import Paper from "@mui/material/Paper";
import styles from "./Verify.module.css";

import Button from "@mui/material/Button";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import { useEffect, useState } from "react";
import Otp from "../otp/Otp";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { IPaymentState } from "../../redux/reducer";
import { IRootState } from "../../redux/store";
import { getCurrencySymbol } from "../../util/getCurrencySymbol";
import { Backdrop, Chip, CircularProgress, Divider, Modal } from "@mui/material";
import { PaymentActions } from "../../redux/action";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface IVerify {
  email: string;
  mfaStatus: boolean;
  mfaType: string;
  code: string;
  transactionType: string;
  nonce: string;
  userID: string;
  paymentSessionId: string;
}

const Verify = () => {
  const paymentState: IPaymentState = useSelector((state: IRootState) => state.payment);

  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [chargeCommission, setChargeCommission] = useState(0);

  const [openError, setOpenError] = useState(false);

  const onErrorModalClose = () => {
    window.location.replace(paymentState.purchaseDetail?.cancelUrl!);
  };

  const [paymentDone, setPaymentDone] = useState(false);

  const onPaymentDone = () => {
    window.location.replace(paymentState.purchaseDetail?.successUrl!);
  };

  const onVerify = () => {
    dispatch(PaymentActions.SetLoadingStatus(true));
    if (!paymentState.sessionDetail || !paymentState.purchaseDetail || !paymentState.txInit || !paymentState.sessionId) return;

    const ssHeader = JSON.stringify({
      version: "1.0",
      clientKey: "END_USER_MOBILE_APP",
      moduleId: "1",
      sessionId: paymentState.sessionDetail.guid,
      accessToken: paymentState.sessionDetail.token,
    });

    const data: IVerify = {
      email: paymentState.sessionDetail.email,
      mfaStatus: paymentState.txInit.mfaStatus,
      mfaType: paymentState.txInit.mfaType,
      code: otp,
      transactionType:
        paymentState.txInit.balance >= (Math.round(parseFloat(paymentState.purchaseDetail.totalPrice) * 10 * 10) + paymentState.txInit.blockedBalance)
          ? "TRANSFER"
          : "DEPOSIT_AND_TRANSFER",
      nonce: paymentState.txInit.nonce,
      userID: paymentState.sessionDetail.userId,
      paymentSessionId: paymentState.sessionId,
    };

    const header = {
      accept: "application/json",
      "ss-header": ssHeader,
      "Content-Type": "application/json",
      Authorization: `Bearer ${paymentState.sessionDetail.token}`,
    };

    axios
      .post(`${process.env.REACT_APP_WALLET_END_PONIT}/wallet/merchantPaymentNonApiVerify`, data, {
        headers: header,
      })
      .then((res) => {
        const response = res.data;
        if (res.status === 200 && response.statusCode === 200) {
          // window.location.replace(paymentState.purchaseDetail?.successUrl!);
          dispatch(PaymentActions.SetLoadingStatus(false));
          setPaymentDone(true);
        } else {
          setError(response["message"]);
          dispatch(PaymentActions.SetLoadingStatus(false));
        }
      })
      .catch((err) => {
        // window.location.replace(paymentState.purchaseDetail?.cancelUrl!);
        dispatch(PaymentActions.SetLoadingStatus(false));
        setOpenError(true);
      });
  };

  const getCommission = (amount: number) => {
    if (!paymentState || !paymentState.sessionDetail) {
      return;
    }

    const ssHeader = JSON.stringify({
      version: "1.0",
      clientKey: "END_USER_MOBILE_APP",
      moduleId: "1",
      sessionId: paymentState.sessionDetail.guid,
      accessToken: paymentState.sessionDetail.token,
    });

    const header = {
      accept: "application/json",
      "ss-header": ssHeader,
      "Content-Type": "application/json",
      Authorization: `Bearer ${paymentState.sessionDetail.token}`,
    };

    const data = { amount, transactionProcessingTypes: "SAME_DAY", transactionType: "CREDIT" };

    axios
      .post(`${process.env.REACT_APP_WALLET_END_PONIT}/wallet/processingFee`, data, {
        headers: header,
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.response["processingFee"]) {
            setChargeCommission(res.data.response["processingFee"] / 100);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        window.location.replace(paymentState.purchaseDetail?.cancelUrl!);
      });
  };

  useEffect(() => {
    if (
      paymentState.purchaseDetail &&
      paymentState.txInit &&
      (Math.round(parseFloat(paymentState.purchaseDetail.totalPrice) * 10 * 10) + paymentState.txInit.blockedBalance) > paymentState.txInit.balance
    ) {
      getCommission((parseFloat(paymentState.purchaseDetail.totalPrice)  -  (paymentState.txInit.balance - paymentState.txInit.blockedBalance) /100 ) * 100);
    }
  }, []);
  
  useEffect(() => {
    if( paymentState.purchaseDetail && paymentState.txInit && chargeCommission > 0){
      getCommission((parseFloat(paymentState.purchaseDetail.totalPrice)  -  (paymentState.txInit.balance - paymentState.txInit.blockedBalance) / 100 ) * 100 + chargeCommission * 100)
      }
  }, [chargeCommission]); //Logs when chargeCommission changes
  

  return (
    <>
      {paymentState.loading && (
        <Backdrop sx={{ color: "green", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={paymentState.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <div>
        {paymentState.purchaseDetail && paymentState.txInit && (
          <div className={styles.container}>
            <Paper variant="outlined">
              <div>
                <div className={styles.head}>
                  <img src="/sk-logo.svg" alt="settleking_logo" className={styles.logoImage} />

                  <div className={styles.titleText}>{`Hi, ${paymentState.txInit.name}`}</div>
                  <div className={styles.balanceContainer}>
                    <AccountBalanceWalletIcon fontSize="small" sx={{ color: "green" }} />
                    <span className={styles.balance}>{getCurrencySymbol("USD", (paymentState.txInit.balance - paymentState.txInit.blockedBalance) / 100)}</span>
                  </div>
                </div>
                <Divider style={{ padding: "12px 24px" }}>
                  <Chip label="Paying" size="medium" sx={{ fontWeight: 600 }} />
                </Divider>
                <div className={styles.body}>
                  <div className={styles.verifyText}>
                    <div className={styles.merchant}>{paymentState.purchaseDetail?.merchantName}</div>
                    {chargeCommission === 0 && (
                      <div className={styles.amount}>
                        {getCurrencySymbol(paymentState.purchaseDetail.currency, parseFloat(paymentState.purchaseDetail.totalPrice))}
                      </div>
                    )}

                    {chargeCommission > 0 && (
                      <div className={styles.amount}>
                        {getCurrencySymbol(
                          paymentState.purchaseDetail.currency,
                          parseFloat(paymentState.purchaseDetail.totalPrice) + chargeCommission
                        )}
                      </div>
                    )}
                    {chargeCommission > 0 && (
                      <div style={{ fontSize: 14, textAlign: "center", padding: 8 }}>
                        {`Your transaction will be processed with a $${chargeCommission} processing fee due to insufficient funds in your wallet. 
                      If you have sufficient balance in your wallet, the transaction will be free of charge.`}
                      </div>
                    )}
                  </div>
                  <Otp separator={<span />} length={6} value={otp} onChange={setOtp} />
                  <div style={{ textAlign: "center", fontSize: 13, padding: 16 }}>
                    Please enter OTP sent to {paymentState.sessionDetail?.mfa}
                  </div>
                  {error.trim() !== "" && <div className={styles.error}>{error}</div>}
                </div>

                <div className={styles.action}>
                  <Button variant="contained" sx={{ width: "80%" }} onClick={onVerify} disabled={otp.length !== 6 ? true : false}>
                    {paymentState.txInit.balance >= Math.round(parseFloat(paymentState.purchaseDetail.totalPrice) * 10 * 10)
                      ? "Verify"
                      : "Deposit"}{" "}
                    & Pay
                  </Button>

                  <Button
                    variant="text"
                    sx={{ fontSize: 11, fontWeight: 600 }}
                    style={{ marginTop: 24 }}
                    color="secondary"
                    onClick={() => {
                      window.location.replace(paymentState.purchaseDetail?.cancelUrl!);
                    }}
                  >
                    Cancel and return to <br></br>
                    {paymentState.purchaseDetail?.merchantName}
                  </Button>
                </div>
              </div>
            </Paper>
            <div className={styles.footer}>
              <div>
                <a href="https://settleking.com/auth/terms-of-service">Terms</a>
                <a href="https://settleking.com/auth/privacy-policy">Privacy Policy</a>
              </div>
              <div className={styles.footerRight}>
                <a href="https://settleking.com/">
                  <img src="/sk-logo.svg" alt="settleking_logo" style={{ maxWidth: 70 }} />
                </a>
                <span>© 2024</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal open={openError} onClose={onErrorModalClose} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className={styles.errorModal}>
          <h2>Error Occurred</h2>
          <ErrorIcon fontSize="large" color="error" />
          <p style={{ textAlign: "center" }}>
            An error occurred while processing your request.<br></br> Please try again later.
          </p>
          <Button
            variant="contained"
            sx={{ fontSize: 11, fontWeight: 600 }}
            style={{ marginTop: 24 }}
            color="error"
            onClick={onErrorModalClose}
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal open={paymentDone} onClose={onPaymentDone} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className={styles.errorModal}>
          <h2>Payment Successful</h2>
          <CheckCircleIcon fontSize="large" color="primary" />
          <p style={{ textAlign: "center" }}>
            {paymentState &&
            paymentState.txInit &&
            paymentState.purchaseDetail &&
            paymentState.txInit.balance >= Math.round(parseFloat(paymentState.purchaseDetail.totalPrice) * 10 * 10) ? (
              <span>
                Payment has been successfully processed. <br /> Thank you for using SettleKing.
              </span>
            ) : (
              <span>
                Payment has been initiated and will be completed within the next 24 hours.<br></br>
                Thank you for using SettleKing.
              </span>
            )}
          </p>
          <Button
            variant="contained"
            sx={{ fontSize: 11, fontWeight: 600 }}
            style={{ marginTop: 24 }}
            color="primary"
            onClick={onErrorModalClose}
          >
            Done
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Verify;
